import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";

export function useLogin() {
  const { mutateAsync, isLoading } = useMutation(
    async (params) => {
      console.log(params);
      return await apiClient({
        url: `${process.env.REACT_APP_API}auth/login`,
        method: "post",
        data: { ...params, password: params.password },
      });
    },
    {
      onSuccess: ({ data }) => {
        console.log(data);
        const { accessToken, refreshToken } = data;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        window.location.replace("/");
      },
      onError: ({ response }) => {
        const { data } = response;
        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return { mutate: mutateAsync, isLoading };
}
