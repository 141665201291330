import React from "react";
import { Form } from "react-bootstrap";
import adminApi from "../../function/apiConfig";

const FunctionControllers = ({
  data,
  refetch,
  typeConvert,
  useFunctions = [],
  toggleApiUrl = (id) => {},
  confirmText = ({ type, use }) => {},
  successText = ({ type, use }) => {},
  apiMethod = "post",
  typeKey = "type",
  idKey = "id",
}) => {
  const id = data?.[idKey] ?? null;

  const onClickRadio = ({ id, type, use }) => {
    const confirm = window.confirm(confirmText({ type, use }));
    if (confirm) {
      adminApi[apiMethod](toggleApiUrl(id), {
        [typeKey]: type,
        use,
      })
        .then(function (response) {
          alert(successText({ type, use }));
          refetch();
        })
        .catch(() => {
          alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
        });
    }
  };

  const renderSwitchByStatus = ({
    status,
    id,
    type,
    isReadOnly,
    typeConvert,
  }) => {
    return (
      <Form.Switch
        key={id + type}
        label={typeConvert(type)}
        checked={status}
        className="custom-switch"
        style={{ cursor: "pointer" }}
        onChange={
          isReadOnly
            ? () => {}
            : () => {
                onClickRadio({ id, type, use: !status });
              }
        }
        readOnly={isReadOnly}
        disabled={isReadOnly}
      />
    );
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "0.8rem 0" }}>
      {useFunctions.map((obj) =>
        renderSwitchByStatus({
          status: obj.status,
          id,
          type: obj.type,
          isReadOnly: obj.isReadOnly,
          typeConvert,
        })
      )}
    </div>
  );
};

export default FunctionControllers;
