import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useStores } from "../../hooks/store/useStores";
import Btn from "../admin/Btn";
import { apiClient } from "../../reactQuery/api";
import { AxiosError } from "axios";
import { queryKeys } from "../../reactQuery/constants";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Modal } from "react-bootstrap";
import adminApi from "../../function/apiConfig";
import { setCommas } from "../../function/common";
import {
  StoreBillingHistoryModal,
  StoreBillingModal,
} from "./StoreBillingModals";
import FunctionControllers from "./FunctionControllers";
import MembershipThemeControllers from "./MembershipThemeControllers";

const storeStatuses = [
  {
    label: "운영 중",
    value: "OPEN",
    code: 1,
  },
  {
    label: "일시정지",
    value: "PAUSE",
    code: 2,
  },
  {
    label: "운영 중단",
    value: "CLOSED",
    code: 3,
  },
  {
    label: "심사 중",
    value: "UNDER_REVIEW",
    code: 4,
  },
];

export default function StoreBoard({
  setTotalElement,
  setTotalPage,
  searchState,
  page,
  onLogoDownBtnClick,
  onQRDownBtnClick,
  modal,
  setModal,
}) {
  const [storeFunctionModal, setStoreFunctionModal] = useState({
    show: false,
    data: null,
  });
  const [billingModal, setBillingModal] = useState({
    show: false,
    data: {
      storeId: "",
      billingStartDate: "",
      billingPrice: "",
      billingType: "",
      billingSubjectType: "",
      storeGroupId: "",
      billingStatus: "",
    },
  });
  const [billingHistoryModal, setBillingHistoryModal] = useState({
    show: false,
    data: null,
  });
  const { stores, refetch } = useStores({
    searchState,
    page,
    onSuccessCallback: (res) => {
      if (storeFunctionModal.show) {
        const { content } = res;
        const targetStore = content.find(
          (store) => store.id === storeFunctionModal.data.id
        );
        setStoreFunctionModal((prev) => ({
          ...prev,
          data: targetStore,
        }));
      }
    },
  });

  useEffect(() => {
    setTotalElement(stores.totalElements);
    setTotalPage(stores.totalPages);
  }, [setTotalElement, setTotalPage, stores]);

  const acceptStore = (storeId, domain) => {
    adminApi
      .post("/stores/" + storeId + "/examine", {
        acceptStatus: "ACCEPTED",
        domain: domain,
      })
      .then(function () {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const acceptStoreSettlement = (storeId) => {
    adminApi
      .post("/stores/" + storeId + "/store-settlement", {
        approveStatus: "ACCEPTED",
      })
      .then(function () {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const linkPopbill = (storeId) => {
    adminApi
      .post("/stores/" + storeId + "/cash-receipt")
      .then(function (response) {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const linkToss = (storeId) => {
    adminApi
      .post("/stores/" + storeId + "/register-toss")
      .then(function (response) {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const checkDomain = (domain) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(domain)) {
      return false;
    } else {
      return true;
    }
  };
  const clickApprove = (storeId) => {
    const _domain = prompt(
      "승인 후 사용할 가맹점 도메인을 입력해주세요 (영어 또는 숫자만) \n ex) 웨이브온 -> waveoncoffee"
    );
    if (!_domain || !checkDomain(_domain)) {
      return null;
    }
    acceptStore(storeId, _domain);
  };

  const clickLinkPopbill = (storeId) => {
    const _confirm = window.confirm("연동 하시겠습니까?");
    if (!_confirm) {
      return null;
    }
    linkPopbill(storeId);
  };

  const clickLinkToss = (storeId) => {
    const _confirm = window.confirm(
      "********주의********\n토스 KYC 발송 됩니다.\n연동 하시겠습니까?"
    );
    if (!_confirm) {
      return null;
    }
    linkToss(storeId);
  };

  const clickSettle = (storeId) => {
    const _confirm = window.confirm(
      "정산계좌 등록을 수락 하시겠습니까? \n수락하기전에 팝빌연동을 먼저 진행해주세요!!!"
    );
    if (!_confirm) {
      return null;
    }
    acceptStoreSettlement(storeId);
  };

  function renderStoreAcceptByStatus(acceptStatus, storeId) {
    if (acceptStatus.code === 1) {
      return (
        <td>
          <Button
            onClick={() => {
              clickApprove(storeId);
            }}
            variant="outline-secondary"
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            승인하기
          </Button>
        </td>
      );
    } else {
      return (
        <td
          style={{
            minWidth: "5rem",
            wordBreak: "keep-all",
            whiteSpace: "nowrap",
          }}
        >
          {acceptStatus.description}
        </td>
      );
    }
  }

  function renderPopbillByStatus(isLinkedPopbill, storeId) {
    if (isLinkedPopbill) {
      return <span style={{ width: "5.2rem" }}>연동완료</span>;
    } else {
      return (
        <span>
          <Button
            onClick={() => {
              clickLinkPopbill(storeId);
            }}
            variant="outline-secondary"
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            연동하기
          </Button>
        </span>
      );
    }
  }

  function renderTossByStatus(sellerId, storeId) {
    if (sellerId) {
      return <span style={{ width: "5.2rem" }}>연동완료</span>;
    } else {
      return (
        <span>
          <Button
            onClick={() => {
              clickLinkToss(storeId);
            }}
            variant="outline-secondary"
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            연동하기
          </Button>
        </span>
      );
    }
  }

  function renderSettleByStatus(status, storeId) {
    if (status && status.code === 1) {
      return (
        <td>
          <Button
            onClick={() => {
              clickSettle(storeId);
            }}
            variant="outline-secondary"
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            수락 하기
          </Button>
        </td>
      );
    } else if (status) {
      return (
        <td style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
          {" "}
          {status.description}
        </td>
      );
    } else {
      return (
        <td style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
          {" "}
          작성 중
        </td>
      );
    }
  }

  function clickFee(_storeId, _storeName) {
    adminApi.get("/stores/" + _storeId + "/fee").then(function (response) {
      console.log(response);
      const _couponSettleType = response.couponSettleType.description;

      if (response.couponSettleType.code === 3) {
        const contents = response.storeFees
          .map(
            (x) =>
              x.paymentChannel.name +
              ":" +
              Math.round(x.feeRate * 1000000) / 10000 +
              "%"
          )
          .join("\n");
        setModal({
          showModal: true,
          textOfBody: "수수료 방식 :" + _couponSettleType + "\n" + contents,
          textOfHeader: _storeName + " 수수료 정보",
        });
      } else {
        const contents = "수수료 일괄 5.5%";
        setModal({
          showModal: true,
          textOfBody: "수수료 방식 :" + _couponSettleType + "\n" + contents,
          textOfHeader: _storeName + " 수수료 정보",
        });
      }
    });
  }

  const clickStoreFunctions = (store) => {
    setStoreFunctionModal({ show: true, data: store });
  };

  const clickBillingModal = (store) => {
    setBillingModal({ show: true, data: store });
  };

  const clickBillingHistoryModal = (store) => {
    setBillingHistoryModal({ show: true, data: store });
  };

  const mapedStores = stores.content.map((store) => {
    const {
      id,
      storeName,
      ownerName,
      status,
      acceptStatus,
      logoUrl,
      domain,
      createdAt,
      isLinkedPopbill,
      sellerId,
      settleApproveStatus,
      storeBilling,
      membershipThemeType,
    } = store;

    return (
      <tr key={id} className="board-row-except">
        <td
          style={{
            maxWidth: "4rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {id}
        </td>
        {ownerName && storeName && acceptStatus.code !== 4 ? (
          <>
            <td
              style={{
                // maxWidth: "4rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <Link to={"/admin/store/stores/" + id}>{storeName}</Link>
            </td>
            <td
              style={{
                // maxWidth: "4rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {ownerName}
            </td>
          </>
        ) : (
          <td colSpan={2} style={{ fontWeight: 700, color: "#a1a1a1" }}>
            입점 진행 중
          </td>
        )}
        <td
          style={{
            wordBreak: "keep-all",
            whiteSpace: "nowrap",
            minWidth: "8rem",
          }}
        >
          {/* {status.name} */}
          <Form.Select
            onChange={(e) => {
              const changedValue = e.target.value;
              const confirm = window.confirm(
                `${storeName} 가맹점의 운영 상태를 ${storeStatuses.find((stat) => stat.value === e.target.value)
                  .label
                }(으)로 변경하시겠습니까?`
              );
              if (confirm) {
                adminApi
                  .put("/stores/" + id + "/status", {
                    storeStatus: e.target.value,
                  })
                  .then(function (response) {
                    alert(
                      `${storeName} 가맹점의 운영 상태가 ${storeStatuses.find(
                        (stat) => stat.value === changedValue
                      ).label
                      }(으)로 변경되었습니다.`
                    );
                    refetch();
                  })
                  .catch(() => {
                    alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
                  });
              }
              return;
            }}
            value={
              storeStatuses?.find((stat) => stat.code === status?.code).value
            }
          >
            {storeStatuses.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.value === "UNDER_REVIEW"}
              >
                {option.label}
              </option>
            ))}
          </Form.Select>
        </td>
        {/*<td>{acceptStatus.description}</td>*/}
        {renderStoreAcceptByStatus(acceptStatus, id)}
        {/*<td>{settleApproveStatus?.description ||"작성중"}</td>*/}
        {renderSettleByStatus(settleApproveStatus, id)}
        <td>
          <div>
            <div
              style={{
                wordBreak: "keep-all",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
                flexShrink: 0,
              }}
            >
              <strong style={{ width: "50%", flexShrink: 0 }}>팝빌</strong>
              <strong style={{ width: "50%", flexShrink: 0 }}>토스</strong>
            </div>
            <div
              style={{
                wordBreak: "keep-all",
                display: "flex",
                justifyContent: "space-between",
                flexShrink: 0,
              }}
            >
              {renderPopbillByStatus(isLinkedPopbill, id)}
              {renderTossByStatus(sellerId, id)}
            </div>
          </div>
        </td>
        {/* <td>
          <a
            href={`${process.env.REACT_APP_USER_URL}admin/product/products?storeId=${id}`}
            target="_blank"
            rel="noreferrer"
          >
            바로가기
          </a>
          <Link
           to={"/admin/product/products?storeId=" + id}
           className="btn btn-outline-secondary"
          >
           확인하기
          </Link>
        </td> */}
        {/* <td>
         <Btn
           type="EVENT"
           btntext="로고 다운"
           btnStyle=""
           onClick={() => {
             onLogoDownBtnClick(storeName, logoUrl);
           }}
         />
        </td>
        <td style={{ maxWidth: "16rem", wordBreak: "break-all" }}>
          <a
            href={`${process.env.REACT_APP_USER_URL}user/${domain}`}
            target="_blank"
            rel="noreferrer"
          >
            바로가기
          </a>
        </td> */}
        <td>
          <Button
            variant="outline-secondary"
            onClick={() => clickFee(id, storeName)}
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            보기
          </Button>
        </td>
        {/*<td>*/}
        {/*  <Btn*/}
        {/*    type="EVENT"*/}
        {/*    btntext="QR다운"*/}
        {/*    btnStyle=""*/}
        {/*    onClick={() => {*/}
        {/*      onQRDownBtnClick({ domain });*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</td>*/}
        <td style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
          {createdAt.replace("T", " ").substring(0, 16)}
        </td>
        {/*{renderETCByType(acceptStatus.code, id)}*/}
        <td>{storeBilling?.billingStatus?.description ?? "-"}</td>
        <td>
          {storeBilling?.billingStartDate?.replace("T", " ").substring(0, 16) ??
            "-"}
        </td>
        <td>
          {storeBilling?.billingPrice
            ? setCommas(storeBilling?.billingPrice) + " 원"
            : "-"}
        </td>
        <td>{storeBilling?.billingType?.description ?? "-"}</td>
        <td>{storeBilling?.billingSubjectType?.description ?? "-"}</td>
        <td>
          <Button
            variant="outline-secondary"
            onClick={() => clickBillingModal(store)}
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            수정/등록
          </Button>
        </td>
        <td>
          {storeBilling?.storeBillingId ? (
            <Button
              variant="outline-secondary"
              onClick={() => clickBillingHistoryModal(store)}
              style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
            >
              보기
            </Button>
          ) : (
            <></>
          )}
        </td>
        <td>
          <Button
            variant="outline-secondary"
            onClick={() => clickStoreFunctions(store)}
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            관리
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <>
      {mapedStores}
      <StoreFunctionModal
        show={storeFunctionModal.show}
        data={storeFunctionModal.data}
        onClickHide={() =>
          setStoreFunctionModal({
            show: false,
            data: null,
          })
        }
        refetch={refetch}
      />
      <StoreBillingModal
        show={billingModal.show}
        data={billingModal.data}
        onClickHide={() =>
          setBillingModal({
            show: false,
            data: null,
          })
        }
        refetch={refetch}
      />
      <StoreBillingHistoryModal
        show={billingHistoryModal.show}
        data={billingHistoryModal.data}
        onClickHide={() =>
          setBillingHistoryModal({
            show: false,
            data: null,
          })
        }
      />
    </>
  );
}

const StoreFunctionModal = ({ show, onClickHide, data, refetch }) => {
  const { id, storeName } = data || {};

  const storeValueLimits = useMemo(
    () => [
      { label: "총 발행한도액", value: data?.storeValueLimit, type: null },
      {
        label: "1회당 발행한도액",
        value: data?.storeValueLimitPerIssue,
        type: "PER_ISSUE",
      },
      {
        label: "멤버십당 보유한도액",
        value: data?.storeMembershipValueLimit,
        type: "MEMBERSHIP_VALUE_LIMIT",
      },
    ],
    [data]
  );

  const useFunctions = useMemo(
    () => [
      {
        status: data?.useMenu,
        type: "MENU",
      },
      {
        status: data?.usePass,
        type: "PASS",
      },
      {
        status: data?.useDefaultMenuAlimTalk,
        type: "DEFAULT_MENU_ALIM_TALK",
      },
      {
        status: data?.useAutoCashReceipt,
        type: "AUTO_CASH_RECEIPT",
      },
      {
        status: data?.usePrePaid,
        type: "PREPAID",
      },
      {
        status: data?.useBulkCreate,
        type: "BULK_CREATE",
      },
      {
        status: data?.useReservation,
        type: "RESERVATION",
      },
      {
        status: data?.useDoorSystem,
        type: "DOOR_SYSTEM",
      },
      {
        status: data?.useMenuAutoCancel,
        type: "MENU_AUTO_CANCEL",
        isReadOnly: true,
      },
      {
        status: data?.useKids,
        type: "KIDS",
      },
      {
        status: data?.usePromotionCode,
        type: "PROMOTION_CODE",
      },
      {
        status: data?.usePostPayment,
        type: "POST_PAYMENT",
        isReadOnly: true,
      },
      {
        status: data?.useCouponOnPage,
        type: "USE_COUPON_ON_PAGE",
      },
    ],
    [data]
  );

  const onClickRadio = ({ id, storeName, type, use }) => {
    const confirm = window.confirm(
      `${storeName} 가맹점 ${storeFunctionConvert(type)} ${use ? "사용하도록" : "사용하지 않도록"
      } 변경 하시겠습니까?`
    );
    if (confirm) {
      adminApi
        .post("/stores/" + id + "/store-function", {
          type,
          use,
        })
        .then(function (response) {
          alert(
            `${storeName} 가맹점 ${storeFunctionConvert(type)} ${use ? "사용하도록" : "사용하지 않도록"
            } 변경 되었습니다.`
          );
          refetch();
        })
        .catch(() => {
          alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
        });
    }
    return;
  };

  const onChangeStoreValueLimit = ({ id, storeName, limit = 0, type }) => {
    const apiUrl = () => {
      if (type) {
        if (type === "PER_ISSUE") {
          return `/stores/${id}/store-value-limit-per-issue`;
        }
        if (type === "MEMBERSHIP_VALUE_LIMIT") {
          return `/stores/${id}/store-membership-value-limit`;
        }
      } else {
        return `/stores/${id}/store-value-limit`;
      }
    };
    adminApi
      .post(apiUrl(), {
        limit,
      })
      .then(function (response) {
        alert(
          `${storeName} 가맹점 변경한도 ${setCommas(
            limit
          )} 원으로 변경 되었습니다.`
        );
        refetch();
      })
      .catch(() => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
      });
  };

  const renderStoreValueLimit = ({ label, value, type }) => {
    return (
      <span
        key={id + value}
        style={{
          cursor: "pointer",
          wordBreak: "keep-all",
          whiteSpace: "nowrap",
          width: "calc(50% - 0.4rem)",
          fontWeight: "500",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() => {
          const price = window.prompt(
            `변경할 ${label}을 입력하세요.`,
            value ?? ""
          );
          if (price && !isNaN(parseInt(price))) {
            onChangeStoreValueLimit({ id, storeName, limit: price, type });
          } else {
            alert("금액을 올바르게 입력해 주세요.");
          }
        }}
      >
        <span>{label} :</span>
        <strong>{value ? setCommas(value) + " 원" : "설정 값 없음"}</strong>
      </span>
    );
  };

  return (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header>가맹점 설정 관리</Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
            fontWeight: "700",
            borderBottom: "1px solid #ff5864",
          }}
          className="pb-2 mb-2"
        >
          <div style={{ fontWeight: "700", color: "#ff5864" }}>
            가맹점 발행 한도 (클릭하여 수정)
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "0.8rem" }}>
            {storeValueLimits.map((obj) =>
              renderStoreValueLimit({
                label: obj.label,
                value: obj.value,
                type: obj.type,
              })
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
            fontWeight: "700",
            borderBottom: "1px solid #ff5864",
          }}
          className="pb-2 mb-2"
        >
          <div style={{ fontWeight: "700", color: "#ff5864" }}>
            멤버십 테마
          </div>
          <MembershipThemeControllers
            data={data}
            refetch={refetch}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <div style={{ fontWeight: "700", color: "#ff5864" }}>
            가맹점 기능 목록
          </div>
          <FunctionControllers
            data={data}
            refetch={refetch}
            typeConvert={storeFunctionConvert}
            useFunctions={useFunctions}
            toggleApiUrl={(id) => "/stores/" + id + "/store-function"}
            confirmText={({ type, use }) =>
              `${storeName} 가맹점 ${storeFunctionConvert(type)} ${use ? "사용하도록" : "사용하지 않도록"
              } 변경 하시겠습니까?`
            }
            successText={({ type, use }) =>
              `${storeName} 가맹점 ${storeFunctionConvert(type)} ${use ? "사용하도록" : "사용하지 않도록"
              } 변경 되었습니다.`
            }
            apiMethod="post"
            typeKey="type"
            idKey="id"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClickHide()}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const storeFunctionConvert = (storeFunction) => {
  switch (storeFunction) {
    case "MENU":
      return "주문형 상품 기능";
    case "PASS":
      return "패스방식 기능";
    case "AUTO_CASH_RECEIPT":
      return "현금영수증 자동 자진발급 기능";
    case "PREPAID":
      return "현장발행 기능";
    case "DEFAULT_MENU_ALIM_TALK":
      return "추가 알림톡 발송 기능";
    case "BULK_CREATE":
      return "대량발행 기능";
    case "RESERVATION":
      return "예약 상품 기능";
    case "DOOR_SYSTEM":
      return "출입관제 기능";
    case "MENU_AUTO_CANCEL":
      return "메뉴 주문 자동취소 기능";
    case "KIDS":
      return "키즈카페 솔루션 사용";
    case "PROMOTION_CODE":
      return "프로모션 코드 기능";
    case "POST_PAYMENT":
      return "후불 결제 방식";
    case "USE_COUPON_ON_PAGE":
      return "쿠폰 페이지 내 사용처리 기능";
    default:
      return "알 수 없는 기능";
  }
};
const membershipThemeConvert = (storeFunction) => {
  switch (storeFunction) {
    case "APP":
      return "APP 테마";
    default:
      return "알 수 없는 기능";
  }
};