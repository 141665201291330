import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FunctionControllers from "../../../components/store/FunctionControllers";
import { useMemo } from "react";

const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};

export default function StoreGroupBoard({
  data,
  onClickStoreList = () => {},
  refetch,
  storeGroupFunctionModal,
  setStoreGroupFunctionModal,
}) {
  const navigate = useNavigate();
  const mappedCardInfo = data.map((card) => {
    const {
      storeGroupId = 0,
      hqStore = {
        storeId: 0,
        storeName: "",
      },
      storeList = [],
      feeRate = 0,
      desc = null,
      logImageUrl = null,
      name = "",
    } = card;

    const clickStoreFunctions = (store) => {
      setStoreGroupFunctionModal({ show: true, data: store });
    };

    return (
      <tr key={storeGroupId} className="board-row-except">
        <Td>{storeGroupId}</Td>
        <Td>{name ?? "-"}</Td>
        <Td>{desc ?? "-"}</Td>
        <Td>{hqStore ? hqStore.storeName : "-"}</Td>
        {/* <Td>{storeList ? storeList.length : "-"}</Td> */}
        {/* <Td>{logImageUrl ? logImageUrl : "-"}</Td> */}
        <Td>{feeRate ?? "-"}</Td>
        {/* {storeList.length > 0 && ( */}
        {/* <Button
            onClick={() =>
              onClickStoreList({ storeGroupId, storeList, groupName: name })
            }
          >
            개별 권한 수정
          </Button> */}
        {/* )} */}
        <Td>
          <Button
            variant="outline-secondary"
            onClick={() => clickStoreFunctions(card)}
            style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
          >
            관리
          </Button>
        </Td>
        <Td>
          {storeGroupId && (
            <Button
              onClick={() =>
                navigate(`/admin/store/group/detail?id=${storeGroupId}`)
              }
            >
              상세보기
            </Button>
          )}
        </Td>
      </tr>
    );
  });

  return (
    <>
      {mappedCardInfo}
      <StoreGroupFunctionModal
        show={storeGroupFunctionModal.show}
        data={storeGroupFunctionModal.data}
        onClickHide={() =>
          setStoreGroupFunctionModal({
            show: false,
            data: null,
          })
        }
        refetch={refetch}
      />
    </>
  );
}

const StoreGroupFunctionModal = ({ show, onClickHide, data, refetch }) => {
  const { name } = data || {};

  const useFunctions = useMemo(
    () => [
      {
        status: data?.useEntireMembershipQuery,
        type: "ENTIRE_MEMBERSHIP_QUERY",
      },
      {
        status: data?.useMenuSync,
        type: "MENU_SYNC",
        isReadOnly: data?.type?.code !== 2,
      },
      {
        status: data?.hasPrePaidPublishAuthority,
        type: "PREPAID",
        isReadOnly: data?.type?.code !== 2,
      },
      {
        status: data?.hasPrePaidCrudAuthority,
        type: "UPDATE_PREPAID_PRODUCT",
        isReadOnly: data?.type?.code !== 2,
      },
      {
        status: data?.hasMenuCrudAuthority,
        type: "UPDATE_MENU_PRODUCT",
        isReadOnly: data?.type?.code !== 2,
      },
    ],
    [data]
  );

  return (
    <Modal show={show} onHide={onClickHide}>
      <Modal.Header>그룹 설정 관리</Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <div style={{ fontWeight: "700", color: "#ff5864" }}>
            그룹 기능 목록
          </div>
          <FunctionControllers
            data={data}
            refetch={refetch}
            typeConvert={storeGroupFunctionConvert}
            useFunctions={useFunctions}
            toggleApiUrl={(id) => `store-group/${id}/store-group-function`}
            confirmText={({ type, use }) =>
              `${name} 그룹 ${storeGroupFunctionConvert(type)} ${
                use ? "사용하도록" : "사용하지 않도록"
              } 변경 하시겠습니까?`
            }
            successText={({ type, use }) =>
              `${name} 그룹 ${storeGroupFunctionConvert(type)} ${
                use ? "사용하도록" : "사용하지 않도록"
              } 변경 되었습니다.`
            }
            apiMethod="put"
            typeKey="storeGroupFunctionType"
            idKey="storeGroupId"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClickHide()}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const storeGroupFunctionConvert = (storeGroupFunction) => {
  switch (storeGroupFunction) {
    case "ENTIRE_MEMBERSHIP_QUERY":
      // 소속 가맹점의 모든 멤버십 회원 목록 조회 권한 컨트롤을 Flexday로부터 위임 받았는지
      return "멤버십 전체 조회 권한 위임";
    case "MENU_SYNC":
      // 프랜차이즈 그룹의 경우, 하위 가맹점들이 주문형 메뉴를 복제할 수 있게 하는 기능을 열어줄 것인지
      return "메뉴 동기화 기능 사용";
    case "PREPAID":
      // 프랜차이즈 그룹의 경우, 하위 가맹점들의 현장발행 권한 컨트롤을 Flexday로부터 위임 받았는지
      return "현장발행 권한 위임";
    case "UPDATE_PREPAID_PRODUCT":
      // 프랜차이즈 그룹의 경우, 하위 가맹점들의 선불카드 제작, 수정 권한 컨트롤을 Flexday로부터 위임 받았는지
      return "선불 상품 수정 권한 위임";
    case "UPDATE_MENU_PRODUCT":
      // 프랜차이즈 그룹의 경우, 하위 가맹점들의 주문메뉴 제작, 수정 권한 컨트롤을 Flexday로부터 위임 받았는지
      return "메뉴 수정 권한 위임";
    default:
      return "알 수 없는 기능";
  }
};
