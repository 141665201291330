import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import adminApi from "../../function/apiConfig";

const MembershipThemeControllers = ({ data, refetch }) => {
  const [selectedCode, setSelectedCode] = useState(null);

  console.log(data?.membershipThemeType?.code);

  useEffect(() => {
    if (data?.membershipThemeType?.code) {
      setSelectedCode(data?.membershipThemeType?.code);
    }
  }, [data]);

  const onClickRadio = ({ id, storeName, label, value, code }) => {
    const confirm = window.confirm(
      `${storeName} 가맹점 멤버십 테마를 ${label}로 변경하시겠습니까?`
    );

    if (!confirm) {
      return;
    }

    adminApi
      .post(`/stores/${id}/store-membership-theme`, {
        membershipThemeType: value,
      })
      .then(() => {
        alert(`${storeName} 가맹점 멤버십 테마가 ${label}로 변경되었습니다.`);
        setSelectedCode(code);
        refetch();
      })
      .catch(() => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
      });
  };

  return (
    <Col xs={12} className="mb-4">
      <Row className="align-items-center">
        <Col>
          <Form className="form-radio">
            <div key={`inline-radio`} className="radiobox">
              {membershipThemeStatuses.map(({ label, value, code }, index) => (
                <Form.Check
                  key={`filter1-${index}`}
                  inline
                  label={label}
                  name="Filter-1"
                  type={"radio"}
                  id={`inline-radio-${index + 1}`}
                  value={value}
                  checked={selectedCode === code}
                  onChange={() =>
                    onClickRadio({
                      id: data?.id,
                      storeName: data?.storeName,
                      label,
                      value,
                      code,
                    })
                  }
                />
              ))}
            </div>
          </Form>
        </Col>
      </Row>
    </Col>
  );
};

const membershipThemeStatuses = [
  {
    label: "기본",
    value: "BASIC",
    code: 1,
  },
  {
    label: "앱 스타일",
    value: "APP",
    code: 2,
  },
];

export default MembershipThemeControllers;
