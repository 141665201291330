import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Btn from "../../../components/admin/Btn";
import LeftMenu from "../../../components/admin/LeftMenu";
import adminApi from "../../../function/apiConfig";
import NewAdminPagination from "../../../components/admin/NewPagination";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";

export default function MembershipBoard() {
  //TODO 페이지 이동시 2번 렌더링 하는것 잡아야함.
  //TODO state를 묶어야 될듯함..

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [name, setName] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [memberships, setMemberships] = useState([]);
  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const { data: defaultMembershipsQueryData, refetch } = useQuery(
    ["memberships", page, name, phoneNumber],
    () =>
      apiClient({
        url: "/memberships",
        params: {
          page,
          size,
          ...(name && { name }),
          ...(phoneNumber && { phoneNumber }),
        },
      }),
    {
      onSuccess: (res) => {},
      onError: (err) => alert(err.message),
      enabled: false,
      suspense: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  // useQuery의 onSuccess에서 처리하지 않고 useEffect 내에서 처리하는 이유는
  // Suspense 때문입니다!
  // 혹시 궁금하시다면, `https://velog.io/@kjwsx23/React-React-Query-%EC%99%80-setState`
  useEffect(() => {
    if (defaultMembershipsQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultMembershipsQueryData?.data?.totalPages) {
      setTotalPages(defaultMembershipsQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultMembershipsQueryData?.data?.totalElements) {
      setTotalElements(defaultMembershipsQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultMembershipsQueryData?.data?.content?.length > 0) {
      setMemberships(defaultMembershipsQueryData.data.content);
    } else {
      setMemberships([]);
    }
  }, [defaultMembershipsQueryData]);

  const onClickSearch = () => {
    setPage(0);
    refetch();
  };
  // if (isLoading) return <></>;

  const changeNamePost = ({ membershipId, name }) => {
    adminApi
      .post(`memberships/${membershipId}/name`, name)
      .then(() => {
        alert(`"${name}"으로 변경 완료되었습니다.`);
        refetch();
      })
      .catch((err) => alert(err));
  };

  const changeName = ({ membershipId, name }) => {
    const prompt = window.prompt(
      `멤버십 회원 "${name}"님의 변경될 이름을 입력해주세요.`
    );
    if (prompt) {
      changeNamePost({ membershipId, name: prompt });
    } else {
      alert("아무 것도 입력하지 않았습니다.");
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">멤버십 회원 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              {/* 2개형 */}
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">이름</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={
                            (e) => onChangeName(e)
                            // setRequest({ ...request, userName: e.target.value })
                          }
                          type="text"
                          id="userName"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">전화번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={
                            (e) => onChangePhoneNumber(e)
                            // setRequest({ ...request, userName: e.target.value })
                          }
                          type="text"
                          id="userId"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          // onClick={() => inquire()}
                          onClick={() => onClickSearch()}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>가맹점명</th>
                    <th>이름</th>
                    <th>전화번호</th>
                    <th>탍퇴여부</th>
                    <th>가입일</th>
                  </tr>
                </thead>
                <tbody>
                  {/* data 값으로 루프 */}
                  {memberships.map((item, index) => (
                    <tr key={item.membershipId}>
                      <td>{item.storeName}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          changeName({
                            membershipId: item.membershipId,
                            name: item.name,
                          })
                        }
                      >
                        {item.name}
                      </td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.deleted ? "탈퇴" : "-"}</td>
                      <td>
                        {item.createdAt.replace("T", " ").substring(0, 16)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <NewAdminPagination
                // totalPages={pageable.totalPages}
                pageNationSize={10}
                // page={pageable.page * 1}
                // handlePage={movePage}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                // request={request}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
