import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import LeftMenuItem from "./LeftMenuItem";
import { Image } from "react-bootstrap";
import { isProduction } from "../../function/common";

export default function LeftMenu(props) {
  const pathName = useLocation().pathname;

  function getPathByDepth(path, depth) {
    let pathSplit = path.split("/");
    return (
      "/" +
      pathSplit
        .filter((v, index) => {
          if (index <= depth) return v;
        })
        .join("/")
    );
  }

  const sideBar = [
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_dashboard.png",
      name: "대시보드",
      path: "/admin/home",
      move: false,
      children: [{ name: "대시보드", path: "/admin/home" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_store.png",
      name: "가맹점",
      path: "/admin/store",
      move: false,
      children: [
        { name: "가맹점 목록", path: "/admin/store/stores" },
        { name: "가맹점 Display", path: "/admin/store/displays" },
        {
          name: `가맹점 ${isProduction() ? '"flexday2022"' : '"jyy9501"'} 연동`,
          path: "/admin/store/connect",
        },
        {
          name: "가맹점 그룹 관리",
          path: "/admin/store/group",
        },
        {
          name: "인바운드 발송 로그",
          path: "/admin/store/inbounds-log",
        },
      ],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_store.png",
      name: "출입관제",
      path: "/admin/doorSystems",
      move: true,
      // move: false,
      // children: [
      //   { name: "출입관제 서버 목록", path: "/admin/doorSystem/servers" },
      // ],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_gift.png",
      name: "상품",
      path: "/admin/product",
      move: false,
      children: [
        { name: "상품 목록", path: "/admin/product/products" },
        { name: "연동 상품 목록", path: "/admin/product/chain-products" },
        { name: "커머스 연동 목록", path: "/admin/product/commerce-products" },
        { name: "상품 변동 내역", path: "/admin/product/product-logs" },
        { name: "실물카드 발급", path: "/admin/product/physical-card" },
      ],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_member.png",
      name: "회원",
      path: "/admin/user",
      move: false,
      children: [
        { name: "회원 목록", path: "/admin/user/users" },
        { name: "가맹점 회원 목록", path: "/admin/user/store-users" },
        { name: "멤버십 회원 목록", path: "/admin/user/memberships" },
      ],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_gift.png",
      name: "교환권",
      path: "/admin/coupon",
      move: false,
      children: [
        { name: "교환권 목록", path: "/admin/coupon/coupons" },
        { name: "교환권 변경내역", path: "/admin/coupon/coupon-logs" },
        { name: "교환권 결제내역", path: "/admin/coupon/coupon-payments" },
      ],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_calculator.png",
      name: "정산",
      path: "/admin/settlement",
      move: false,
      children: [{ name: "수수료 정산", path: "/admin/settlement/fee" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_calculator.png",
      name: "결제",
      path: "/admin/payment",
      move: false,
      children: [
        { name: "통합 결제 내역", path: "/admin/payment/payment-summaries" },
        { name: "플렉스데이 내역", path: "/admin/payment/payments" },
        { name: "스마트 스토어", path: "/admin/payment/smartStore" },
        {
          name: "스마트 스토어(일반 상품)",
          path: "/admin/payment/smartStoreNonPrepaid",
        },
        { name: "키오스크", path: "/admin/payment/kiosk" },
      ],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_member.png",
      name: "어드민",
      path: "/admin/admins",
      move: false,
      children: [{ name: "어드민 목록", path: "/admin/admins/users" }],
    },
    // {
    //   imgsrc:
    //     "https://www.flexdaycdn.net/public/images/static/ui/icon_store.png",
    //   name: "통합 키오스크 관리",
    //   path: "/admin/kiosks",
    //   move: true,
    // },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_member.png",
      name: "공지",
      move: false,
      children: [
        {
          name: "공지사항 관리",
          path: "/admin/notices",
        },
        // {
        //   name: "업데이트 공지",
        //   path: "/admin/updateNotices",
        // },
      ],
    },
    // {
    //   imgsrc:
    //     "https://www.flexdaycdn.net/public/images/static/ui/icon_member.png",
    //   name: "FAQ 관리",
    //   path: "/admin/faqs",
    //   move: true,
    // },
  ];

  return (
    <>
      <div className="LeftMenu">
        <div className="p-4 pt-5">
          <Image
            src="https://www.flexdaycdn.net/public/images/static/ui/flexday_logo_white.png"
            className="img-fluid"
          ></Image>
        </div>
        <Accordion defaultActiveKey={getPathByDepth(pathName, 2)} flush>
          {sideBar.map((value) => (
            <LeftMenuItem
              imgsrc={value.imgsrc}
              name={value.name}
              path={value.path}
              key={value.name}
              move={value.move}
              children={value.children}
            />
          ))}
        </Accordion>
      </div>
    </>
  );
}
