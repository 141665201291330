import axios from "axios";

const token = localStorage.getItem("accessToken");

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    ...(token && { Authorization: `Bearer ${token}` }),
  },
});

apiClient.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
  {
    synchronous: false,
  }
);

apiClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/");
      return alert("토큰이 유효하지 않아 로그아웃됩니다.");
    }
    return Promise.reject(err);
  },
  {
    synchronous: false,
  }
);
